<template>
<div style="">
    <vc-loading :processing="processing" :errors="errors" @close="processing=false;" />

    <div v-if="dialogDissaproved">
        <v-dialog v-model="dialogDissaproved" width="400" persistent>
            <v-card>
                <vc-header title="Motivo" close @close="dialogDissaproved=false"></vc-header>
                <!-- <v-container> -->
                <v-row style="margin:auto">
                    <v-col cols="12">
                        <vc-textarea label="Motivo de Desaprobación" autofocus v-model="transactionApproved.AtsObservation" />
                    </v-col>
                    <v-col cols="12">
                        <v-btn elevation="0" color="primary" block small @click="disapproved()">Desaprobar</v-btn>
                    </v-col>
                </v-row>
                <!-- </v-container> -->
            </v-card>
        </v-dialog>
    </div>

    <!--  <v-footer style="border-radius: 0px;font-family:Calibri" dense flat class="elevation-0" color="white">

        <h5 style="margin-top:5px !important;font-size:16px;font-weight:600 ;color:#858585;"> {{title}}</h5>
    </v-footer> -->
    <v-footer style="border-radius: 5px;font-family:Calibri" dense flat class="body-2 font-weight-bold" :color="'#f5f5f5'">
        <!-- <v-toolbar fixed class="elevation-0" dense height="43" color="white" style="border-radius: 0px;font-family:Calibri"> -->
        <!-- <v-toolbar-title>
            <p style="margin-top:20px !important;font-size:16px;font-weight:600 ;color:#858585;">
                {{ title }}</p>
        </v-toolbar-title> -->
        <p class="mb-0 mt-0" style="margin-top:0px !important;font-size:14px;font-weight:600 ;color:#858585;">
            {{ title }}</p>

        <v-spacer></v-spacer>
        <slot name="options"> </slot>

        <!-- //Inicio Botones -->
        <!-- //Transacciones -->
        <!-- <v-tooltip bottom="" v-if="approve">
            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" small @click="approved()">
                    <v-icon color="info" style="font-size:16px;"> fa fa-repeat</v-icon>
                </v-btn>
            </template>
            <span>Solicitar Reajuste</span></v-tooltip> -->

        <v-tooltip bottom="" v-if="approve">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0" class="capitalize ml-1 mr-1" v-on="on" small @click="approved()">
                    <v-icon color="green" style="font-size:16px;margin-right:5px;"> far fa-thumbs-up</v-icon>Aprobar
                </v-btn>
            </template>
            <span>Aprobar</span></v-tooltip>

        <v-tooltip bottom="" v-if="approve">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0" text class="capitalize ml-1 mr-1" v-on="on" small @click="dialogDissaproved = true">
                    <v-icon color="red" style="font-size:16px;margin-right:5px;"> far fa-thumbs-down</v-icon>Desaprobar
                </v-btn>
            </template>
            <span>Desaprobar</span></v-tooltip>
        <!-- Fin Transacciones -->
       <!--  {{JSON.parse(localStorage.getItem("UsrExtern"))}}
        {{this.$fun.getUserInfo().GenWorkerCE.WrkAprrovedCircuit}} -->
        <!-- {{usrExtern}}
        {{$fun.getUserInfo().GenWorkerCE}}
        {{worker}} -->

        <v-tooltip bottom="" v-if="download && (worker !== null ? this.$fun.getUserInfo().GenWorkerCE.WrkAprrovedCircuit : false) && !usrExtern">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0" class="capitalize mr-1 ml-1" v-on="on" small @click="$emit('download')">
                    <v-icon style="font-size: 14px;margin-right:5px;">mdi-cloud-download</v-icon>Descargar
                </v-btn>
            </template>
            <span>Descargar</span></v-tooltip>

        <v-tooltip bottom="" v-if="add && $fun.getSecurity().IsAllowAdd">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0" class="capitalize ml-1 mr-1" v-on="on" small append @click="$emit('add')">
                    <v-icon style="font-size:18px;margin-right:5px;">mdi-plus-circle-outline</v-icon>Agregar
                </v-btn>
            </template>
            <span>Agregar</span>
        </v-tooltip>

        <v-tooltip bottom="" v-if="save">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0" small v-on="on" class="capitalize save ml-1 mr-1" @click="$emit('save')">
                    <v-icon style="font-size:16px;margin-right:5px;">mdi-content-save</v-icon> Guardar
                </v-btn>
            </template>
            <span>Guardar</span>
        </v-tooltip>

        <v-tooltip bottom="" v-if="edit && $fun.getSecurity().IsAllowEdit">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0" class="capitalize ml-1 mr-1" small v-on="on" @click="$emit('edit')">
                    <v-icon style="font-size:14px;margin-right:5px;">mdi-file-edit-outline</v-icon>Editar
                </v-btn>
            </template>
            <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom="" v-if="remove && $fun.getSecurity().IsAllowDelete">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0" class="capitalize ml-1 mr-1" small v-on="on" @click="$emit('delete', 0)">
                    <v-icon style="font-size:18px;margin-right:5px;">mdi-minus-circle-outline</v-icon>Eliminar
                </v-btn>
            </template>
            <span>Eliminar</span>
        </v-tooltip>

        <v-tooltip bottom="" v-if="view && $fun.getSecurity().IsAllowView">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0" class="capitalize ml-1 mr-1" small v-on="on" @click="$emit('view')">
                    <v-icon style="font-size:14px;margin-right:5px;">far fa-eye</v-icon>Visualizar
                </v-btn>
            </template>
            <span>Ver</span>
        </v-tooltip>
        <v-tooltip bottom v-if="clear">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0" class="capitalize ml-1 mr-1" v-on="on" small @click="$emit('clear')">
                    <v-icon style="font-size:18px;margin-right:5px;"> mdi-broom</v-icon>Limpiar
                </v-btn>
            </template>
            <span>Limpiar</span></v-tooltip>
        <!-- <v-divider vertical></v-divider> -->
        <v-tooltip bottom="" v-if="close">
            <template v-slot:activator="{ on }">
                <v-btn class="capitalize mr-1 ml-1" v-on="on" small @click="$emit('close')" elevation="0">
                    <v-icon style="font-size:16px;margin-right:5px;">mdi-close</v-icon>Salir
                    <!-- <i class="fa-solid fa-right-from-bracket"></i> -->
                    <!-- mdi-close -->
                </v-btn>
            </template>
            <span>Salir</span>
        </v-tooltip>
        <!-- <v-divider vertical></v-divider> -->

        <v-tooltip bottom="" v-if="importfile">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0" class="capitalize ml-1 mr-1" v-on="on" small @click="$emit('importfile')">
                    <v-icon style="font-size:18px;margin-right:5px;">mdi-file-import</v-icon>Importar
                </v-btn>
            </template>
            <span>Importar</span>
        </v-tooltip>

        <!--  //Fin Botones -->

        <!-- </v-toolbar> -->
    </v-footer>
</div>
</template>

<script>
import _sApprovalTransaction from "@/services/Security/SecApprovalTransactionService";
export default {
    name: "vcHeader",
    props: {
        title: {
            type: String,
            default: "Vuetify Confirm Dialog Example",
        },

        noDark: {
            type: Boolean,
            default: true,
        },
        close: {
            type: Boolean,
            default: false,
        },
        importfile: {
            type: Boolean,
            default: false,
        },
        download: {
            type: Boolean,
            default: false,
        },

        //Eventos
        save: {
            type: Boolean,
            default: false,
        },
        add: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        remove: {
            type: Boolean,
            default: false,
        },
        restore: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Boolean,
            default: false,
        },
        view: {
            type: Boolean,
            default: false,
        },
        clear: {
            type: Boolean,
            default: false,
        },

        color: null,

        //Transacciones
        approve: {
            type: Boolean,
            default: false,
        },
        optionemit: {
            type: Boolean,
            default: false,
        },
        transactionApproved: {
            type: Object,
            default: null,
        },

    },

    data: () => ({
        dialogDissaproved: false,
        //Loading
        processing: false,
        errors: null,
        usrExtern:null,
        worker:null

    }),
    mounted() {
        this.usrExtern = JSON.parse(localStorage.getItem("UsrExtern"))
        this.worker = this.$fun.getUserInfo().GenWorkerCE || null
    },
    methods: {
        disapproved() {

            if (this.transactionApproved)

                this.$fun.sweetAlert("Seguro de desaprobar Documento?", "question").then((r) => {
                    if (r.value) {
                        this.processing = true
                        this.transactionApproved.AtsStatus = 3;
                        this.transactionApproved.UsrCreateID = this.$fun.getUserID();

                        _sApprovalTransaction.approved(this.transactionApproved, this.$fun.getUserID()).then((rq) => {
                                this.processing = false;
                                this.$fun.sweetAlert("Documento desaprobado", "success");
                                this.$router.go(0)
                                this.$emit("close");
                            },
                            (e) => {
                                this.errors = e.response
                                this.processing = true;
                            });
                    }
                });
            else this.$emit("disapproved");
        },
        approved() {
            /* console.log("approved", this.optionemit);
            if (this.optionemit == true) {
                console.log("optionemit", this.optionemit);
                this.$emit("approved");
                return
            } */

            if (this.transactionApproved && !this.optionemit) {
                console.log("transactionApproved", this.transactionApproved);

                this.$fun.sweetAlert("Seguro de aprobar Documento?", "question").then((r) => {
                    if (r.value) {
                        this.processing = true

                        this.transactionApproved.AtsStatus = 2;
                        this.transactionApproved.UsrCreateID = this.$fun.getUserID();

                        _sApprovalTransaction.approved(this.transactionApproved, this.$fun.getUserID()).then((rq) => {
                                this.processing = false;
                                this.$fun.sweetAlert("Aprobado Correctamente", "success");
                                /* this.$router.go(0) */
                                this.$emit("close");
                            },
                            (e) => {
                                this.errors = e.response
                                this.processing = true;
                            });
                    }
                });
            } else this.$emit("approved");
        },
    },
    watch: {

    },
    computed: {
        colorToolbar() {
            return this.color == null ? localStorage.getItem('colorToolbar') : this.color;
        },
        isMobile() {
            return this.$vuetify.breakpoint.xsOnly;
        }
    },

};
</script>

<style lang="scss" scoped></style>
