<template>
<div>
    <vc-text :placeholder="placeholder" :label="label" :readonly="readonly" @click="filterCrud.ArtDescription = ''; dialogArticle = true;" :clearable="clearable" search-icon v-model="textDescription" />

    <v-dialog max-width="70%" persistent v-model="dialogArticle" v-if="dialogArticle" transition="dialog-bottom-transition">
        <v-card outlined style="overflow: hidden !important">
            <vc-header title="Articulos" close dark @close="dialogArticle = false" :add="selectedItem != null" @add="doubleClick(selectedItem)"></vc-header>
            <v-col class="mt-1">
                <vc-crud search-input noFull noToolbar @rowSelected="selectedRow($event)" title="Artículos " height="300" @doubleClick="doubleClick($event)" :config="configCrud"  :filter="filterCrud" sortable>
                  <!--   <template v-slot:filter>
                        <v-row style="margin-top: -16px">
                            <v-col cols="12">
                                <vc-text search clearable label="Buscar" autofocus v-model="searchText"></vc-text>
                            </v-col>
                        </v-row>
                    </template> -->

                    <template v-slot:ArtStockDisponible="{ row }">
                        <v-chip x-small :color="row.ArtStockDisponible <= 0 ? 'error': 'info'">
                            {{ row.ArtStockDisponible <= 0 ? 0 : row.ArtStockDisponible }}
                        </v-chip>

                    </template>
                    <template v-slot:ArtAnnualService="{ row }">
                        <v-chip x-small :color="row.ArtAnnualService == 1 ? 'info': 'warning'">
                            {{ row.ArtAnnualService == 1 ? 'Si' : 'No' }}
                        </v-chip>

                    </template>
                </vc-crud>
            </v-col>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import _sArticle from "@/services/Logistics/LgsArticleService";
export default {
    props: {
        value: null,
        readonly: {
            default: false
        },
        clearable: {
            default: false
        },
        label: {
            default: "Descripción de Artículo"
        },
        placeholder: {
            default: ""
        },
        Inventoriable:{
            default: 1
        }
    },
    data() {
        return {
            selectedItem: null,
            article: null,
            textDescription: "",
            dialogArticle: false,
            searchText: null,
           /*  parametersPagination: {
                filter: {
                    WhsCode: "01",
                    SecStatus: 1
                },
                draw: 1,
                start: 0,
                length: 10,
                order: [{
                    dir: "asc",
                    column: ""
                }],
                search: "",
            }, */
            filterCrud: {
                ArtCode: "",
                ArtDescription: "",
                WhsName: "",
                WhsCode: "",
                ArtInventoriable: this.Inventoriable
            },
            configCrud: {
                model: {
                    ArtCode: "ID",
                    ArtName: "string",
                    SecStatus: "status",
                    ArtStockDisponible: "int",
                    ArtAnnualService:''
                },

                service: _sArticle,
                headers: [{
                        text: "Código",
                        value: "ArtCode",
                        sortable: false
                    },
                    {
                        text: "Descripción Artículo",
                        value: "ArtDescription",
                        sortable: false
                    },
                    {
                        text: "Und.Medida",
                        value: "TypeUnitOfMeasuarementName",
                        sortable: false,
                        aling: "center",
                    },
                    {
                        text: "Serv. Anual",
                        value: "ArtAnnualService",
                        sortable: false,
                        aling: "center",
                    },
                    
                   /*  {
                        text: "Stock Disponible",
                        value: "ArtStockDisponible",
                        sortable: false,
                        aling: "center",
                    },
                    {
                        text: "Stock Contable",
                        value: "ArtStockContable",
                        sortable: false,
                        aling: "center",
                    }, */
                ],
            },
        };
    },
    methods: {
        selectedRow(items) {
            if (items)
                if (items.length > 0) this.selectedItem = items[0];
        },
        doubleClick(item) {
            this.article = item;
            this.$emit("input", item);
            this.$emit("selected", item);
            this.textDescription = item.ArtDescription;

            this.dialogArticle = false;
        },

        getAnswer() {
            console.log("this.searchText",this.searchText);
            this.filterCrud.ArtDescription = this.searchText
            //if (this.textDescription == null) this.searchText = "";
        },

    },
    created() {
        this.article = this.value;
        if (this.article != null) {
            this.textDescription = this.article.ArtDescription;
        }
        this.debouncedGetAnswer = _.debounce(this.getAnswer, 500);
    },
    watch: {

        searchText() {
            this.debouncedGetAnswer();
        },

        value() {
            this.textDescription = this.value == null ? "" : this.value.ArtDescription;
            this.article = this.value;
        },
    },
};
</script>
